body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
      "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    width: auto!important;
    overflow-x: hidden!important;
    overflow-y: scroll; /* has to be scroll, not auto */
  -webkit-overflow-scrolling: touch;
  }


 