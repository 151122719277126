
.container {
    padding: 0;
    
}
.bioText {
    padding: 5px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

}
 p {
    line-height: 1.5 !important
}
