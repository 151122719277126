.row > .column {
    padding: 0 8px;
  }
  
  .row:after {
    content: "";
    display: table;
    clear: both;
  }
  
  /* Create four equal columns that floats next to eachother */
  .column {
    float: left;
    width: 25%;
  }
  
  /* The Modal (background) */
  .modal {
    
    z-index: 1;
    padding-top: 100px;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: black;
  }
  
  /* Modal Content */
  .modal-content {
    position: relative;
    cursor: pointer;
    background-color: #fefefe;
    margin: auto;
    padding: 0;
    height: 90%;
    width: 90%;
    max-width: 1200px; 

  }


  
  /* The Close Button */
  .close {
    color: white;
    position: absolute;
    top: 0px;
    right: -26px;
    
    font-weight: bold;
    width: auto;
    padding: 16px;
    margin-top: -50px;
    color: white;
    font-weight: bold;
    font-size: 30px;
    transition: 0.6s ease;
    border-radius: 0 3px 3px 0;
    user-select: none;
    -webkit-user-select: none;

    
  
  }
  
  
  
  /* Hide the slides by default */
  .mySlides {
    display: none;
  }
  
  /* Next & previous buttons */
  .prev,
  .next {
    cursor: pointer;
    position: absolute;
    top: 50%;
    width: auto;
    padding: 16px;
    margin-top: -50px;
    color: white;
    font-weight: bold;
    font-size: 20px;
    transition: 0.6s ease;
    border-radius: 0 3px 3px 0;
    user-select: none;
    -webkit-user-select: none;
  }
  
  /* Position the "next button" to the right */
  .next {
    right: 0px;
    border-radius: 3px 0 0 3px;
  }
  
  /* On hover, add a black background color with a little bit see-through */
  .prev:hover,
  .next:hover {
 
    background-color: rgba(0, 0, 0, 0.8);
  }
  
  /* Number text (1/3 etc) */
  .numbertext {
    color: #f2f2f2;
    font-size: 12px;
    padding: 8px 12px;
    position: absolute;
    top: 0;
  }
  
  /* Caption text */
  .caption-container {
    text-align: center;
    background-color: black;
    padding: 2px 16px;
    color: white;
  }
  
  img.demo {
    opacity: 0.6;
  }
  
  .active,
  .demo:hover {
    opacity: 1;
  }
  
  img.hover-shadow {
    transition: 0.3s;
  }
  
  .hover-shadow:hover {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

.omaTexti {
  font-family: "Montserrat", sans-serif;
  text-align: center;
}


.works {
  text-align: center;
  list-style-type: none;
  margin: 0;
  padding: 0px;
}
.works li {
  display: inline;
}
.works a {
  font-family: "Montserrat", sans-serif;
  padding: 12px;
  color: black;
}
  
.works a:hover {
  
}  

