
.shop {

}
.texti {
    text-align: left;
}
.oma {
    /*background-image: url("http://pekkaparviainen.com/gaia.jpg"); */ 
    text-align: center;
    font-family: "Montserrat", sans-serif;
    
}
.omatexti {
    text-align: center;
}

.omatexti > a {
    position: relative;
    color: #000;
    text-decoration: none;
    font-weight: 260;
  }
  
.omatexti > a:hover {
    color: #000;
  }

.omatexti > a:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: #000;
    visibility: hidden;
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transition: all 0.3s ease-in-out 0s;
    transition: all 0.3s ease-in-out 0s;
  }
  .omatexti > a:hover:before {
    visibility: visible;
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }