.shop {

}

.texti {
    text-align: left;
}
.oma2 {
    /*background-image: url("http://pekkaparviainen.com/gaia.jpg"); */ 
    text-align: center;
    font-family: "Montserrat", sans-serif;
    font-size: 15px;
    padding-top: 22px;
    
}
a {
    color: black;
    text-decoration: none;

}
.oma3 {
    text-align: center;
    padding-bottom: 0px;
    color: black;

}
.contactInShop {
    margin-top: 16px;
    padding: 2px;
    border-style: solid;
    border-color: gainsboro;
    border-width: 1px;
    
}

.webShop {
    font-family: "Montserrat", sans-serif;

}
.otsikko {
    text-align: center;
}


