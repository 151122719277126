footer {
    background-color: #2d2d30;
    color: #f5f5f5;
    padding: 3px;
    width: 100%;
    
    bottom: 0;
    position: relative;
    
}
footer a {
    color: #f5f5f5;
}
footer a:hover {
    color: #777;
    text-decoration: none;
}  
.fa {
    padding: 3px;
    font-size: 24px;
    width: 50px;
    text-align: center;
    text-decoration: none;
  }