
html {
	scroll-behavior: smooth;
}

body {
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

}
.news {
	height: 80vh;

	
}

.news-box-text {
    color: white;
	position: absolute;
	background: rgb(44, 44, 44);
	top: 0;
	
	left: 50%;
	width: 43%;
	
	max-height: 400px;
	padding: 15px;
	text-align: center;
	margin-top: 3%;
	transform: skew(-10deg);
	
}


	@media only screen and (min-width: 992px) and (max-width: 1200px) {
		.container1 {
		max-width: 900px !important; 
		
		margin: auto;
		overflow: hidden;
		
		}
		.col-sm-6 img {
			padding-top: 106px;
			height: auto !important;
		}
		.box img {
			height: 440px !important;
		}
		
		
		
		}
		
	@media only screen and (max-width: 555px) {
		.container1 {
			max-width: 100% !important; 
			
			margin: auto;
			overflow: hidden;
			
			}
		}
		button {
			font-size: 12px !important;
		
		}
		
		
		

			
	@media only screen and (max-width: 768px) {
				.container1 {
					max-width: 540px !important; 
					
					margin: auto;
					overflow: hidden;
					
				}
				.news-box-text {
					position: relative;
					transform: skew(0deg);
					width: 100%;
					left: 0 !important; 
					margin-top: 40px;
				}
				
			}	
			

	@media only screen and (min-width: 768px) and (max-width: 991px) {
		.container1 {
			max-width: 723px !important; 
					
			margin: auto;
			overflow: hidden;
					
			}
			.col-sm-6 img {
				padding-top: 106px;
				height: auto !important;
			}
			
	}			
	@media only screen and (min-width: 1200px) and (max-width: 30000px) {
		.container1 {
			max-width: 1120px !important; 
					
			margin: auto;
			overflow: hidden;
					
			}
			.col-sm-6 img {
				padding-top: 0;
				height: auto !important;
			}
			.box img {
				height: 550px !important;
			}
			
	}			