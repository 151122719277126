
.Form {
    padding: 4%;
    margin-top: 17px;
    
}
.btn {
    padding: 10px 20px;
    background-color: #333;
    color: #f1f1f1;
    border-radius: 0;
    transition: .2s;
}
.btn:hover, .btn:focus {
    border: 1px solid #333;
    background-color: #fff;
    color: #000;
}

.icons-contact {
    padding: 1px;
    max-width: 350px;
    width: 100%;
    left: 0px;
}