

li {
    font-weight: 100;
    padding: 1px;
    list-style-type: disc;
    font-size: 15px;
    color: black
}


.imageC {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
}
i :hover {
    cursor: not-allowed
}