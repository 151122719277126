
.what{
    margin:0;
    padding:0;
    line-height: 0px;
    margin-top: -4px;
    

}
.bottom-left {
    position: absolute;
    bottom: 8px;
    left: 16px;
}
h1 , h2 {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-weight: normal;
  font-weight: 260;
}
.omaLinkki {
  text-align: center;

}
.testee h4 {
  font-size: 40px !important;
  font-weight: 300;
  margin-top: 30%;
  margin-bottom: 1%;
  text-align: center;
}

.omaLinkki > a {
    
    position: relative;
    color: #000;
    text-decoration: none;
    font-weight: 260;
  }
  
.omaLinkki > a:hover {
    color: #000;
  }
.omaLinkki > a:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: #000;
    visibility: hidden;
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transition: all 0.3s ease-in-out 0s;
    transition: all 0.3s ease-in-out 0s;
  }
  .omaLinkki> a:hover:before {
    visibility: visible;
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }

/* The slow way */
.make-it-slow {
  box-shadow: 0 1px 2px rgba(0,0,0,0.15);
  transition: box-shadow 0.3s ease-in-out;
}

/* Transition to a bigger shadow on hover */
.make-it-slow:hover {
  box-shadow: 0 5px 15px rgba(0,0,0,0.3);
}

/* The fast way */
.make-it-fast {
  box-shadow: 0 1px 2px rgba(0,0,0,0.15);
}

/* Pre-render the bigger shadow, but hide it */
.make-it-fast::after {
  box-shadow: 0 5px 15px rgba(0,0,0,0.3);
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

/* Transition to showing the bigger shadow on hover */
.make-it-fast:hover::after {
  opacity: 1;
}
/* Create a simple white box, and add the shadow for the initial state */
.box {
 
 
  
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0 1px 0px rgba(0,0,0,0.15);
  transition: all 0.3s ease-in-out;
}

/* Create the hidden pseudo-element */
/* include the shadow for the end state */


.box::after {
  content: '';
  position: absolute;
 
  opacity: 0;
  border-radius: 5px;
  box-shadow: 0 5px 15px rgba(0,0,0,0.3);
  transition: opacity 0.3s ease-in-out;
}

/* Scale up the box */
.box:hover {
 /* transform: scale(1.02, 1.02); */
}

/* Fade in the pseudo-element with the bigger shadow */
.box:hover::after {
  opacity: 1;
}
.uusiImage {
  height: 780px;
  
  
  
}






