.cardFlex {
  color: rgb(0, 0, 0);
  background: rgb(255, 255, 255);
  text-align: center;
  padding: 1em;
}

.cardFlex h4 {
  color: rgb(53, 53, 53);
  font-size: 2rem;
  text-align: center;
  padding: 20px
  
}

.flip {
  position: relative;
}
.flip p {
  color: black !important;
}

.flip > .front,
.flip > .back {
  display: block;
  transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transition-duration: 0.5s;
  transition-property: transform, opacity;
}
.flip > .front {
  transform: rotateY(0deg);
}
.flip > .back {
  position: absolute;
  opacity: 0;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  transform: rotateY(-180deg);
}
.flip:hover > .front {
  transform: rotateY(180deg);
}
.flip:hover > .back {
  opacity: 1;
  transform: rotateY(0deg);
}
.flip.flip-vertical > .back {
  transform: rotateX(-180deg);
}
.flip.flip-vertical:hover > .front {
  transform: rotateX(180deg);
}
.flip.flip-vertical:hover > .back {
  transform: rotateX(0deg);
}

.trigger {
  margin:0px;
  outline:none;
  vertical-align: top
}

.flip {
  position: relative;
  display: inline-block;
  margin-right: 2px;
  margin-bottom: 1em;
  width: 100%;
  max-width: 500px;
  
}
.flip > .front,
.flip > .back {
  display: block;
  color:black;
  width: inherit;
  background-size: cover !important;
  background-position: center !important;
  height: 320px;
  padding: 1em 2em;
  background: #eeeeee;
  border-radius: 10px;
}
.flip > .front p,
.flip > .back p {
  font-size: 0.9125rem;
  line-height: 160%;
  color: #999;
}

.text-shadow {
    text-shadow: 2 2 2;
}


/* Styled scroll animation not fixed */



.test {
  display: none;
  position: fixed;
  transition: 100px 0.3s ease-out;
  width: 100px;
  height: 300px;
  top: 30%;
  left: 0%;
  overflow: hidden;
  text-align: center;
  background-color: transparent;
  color: rgb(19, 18, 19);
  
  
}
.test-image {
  display: none;
  position: fixed;
  transition: 100px 0.5s ease-out;
  width: 100%;
  height: 400px;
  top: 10%;
  width: 50%;
  margin: 0 auto;
  
  overflow: hidden;
  
  background-color: transparent;
  color: rgb(19, 18, 19);
  
  
}
.backIm-flex {
  display: flex;
}
.backIm2-flex {
  
  display: flex;
  
}
.testee-back {
  background-color: #ffffff;
  opacity: 0;
  max-width: 500px;
  width: 450px;
}
.new-bio-backround {
  background-color: rgb(235, 235, 235);
  height: auto;
  
}
.new-bio-image {
  margin-top: 0%;
  height: 400px;
}
.new-bio-desk {
  display: flex;
 
}

.new-bio {
  display: flex;
  width: 100%;
  
}
.new-bio-social .fa {
  text-align: center;
  margin-top: 6px;
}
.new-bio-social a {
  font-size: 35px; 
  
}
.new-bio-social  :hover {
  color: rgb(126, 123, 123);
}

.testee {
  margin-top: 0%;
  
  width: 100%;
  padding: 10px;
  
}
.testee-image img {
  
  height: auto;
  width: 100%;
  padding-left: 14px;
}
.box-color {
  background-color: #f1f1f1;
  width: 60%;
}
.box-color1 {
  background-color: #fcfcfc;
  width: 55.8%;
}
.box-color3 {
  width: 100%;
  background-color: #dcece9;

}
.test hi {
  font-size: 50px;
}
.test p {
  padding:  30px;
}
.test1 {
  display: none;
  position: fixed;
  transition: 100px 0.3s ease-out;
  
  max-width: 35%;
  height: auto;
  top: 30%;
  padding: 20px;
  
  overflow: hidden;
  text-align: left;
  background-color: transparent;
  color: rgb(19, 18, 19);
  
  
}
.test1 hi {
  font-size: 50px;
}
.test1 p {
  padding:  0px;
}
.zap {
  width: 100%;
  display: block;
  justify-content: center;
  
  
}
.bio-matsku {
  display: flex;
}


@media screen and (max-width: 600px){
    .flip {
      width: 100%;
    }
    .test1 {
      max-width: 100% !important;
    }
    .backIm-flex {
      display: grid;
    }
    .new-bio-backround {
      height: auto !important;
    }
    .new-bio {
      display: grid;
    }
    .new-bio-backround {
      background-color: #f8f8f8;
    }
    .testee p{
      text-align: left;
    
    }
    .new-bio img {
      width: 100%;
      margin-top: 0px !important;
    }
    .new-bio-social .fa {
      text-align: left;
      
    }
    .jumbo2 {
      margin-top: 20px;
    }
    .jumbo-text h2 {
      padding-top: 15px;
      font-size: 50px;
      
    }
    .jumbo-text h3 {
      font-size: 42px;
    }
    .new-bio-social-mobile {
      display: block;
      padding-top: 20px;

      text-align: center;
    }
    .new-bio-social-mobile .fa {
      font-size: 40px;
      color: rgb(75, 73, 73)

    }
    .testee h4 {
      font-size: 20px !important;
      font-weight: 100;
      margin-top: 2% !important;
      margin-bottom: 1%;
      padding: 10px !important;
      
    }
    .testee-image img {
     
      width: 100%;
      padding-left: 0px !important;
    }
    .works li {
      display: block !important;
    }
    .bio-kuva {
      padding-top: 5px !important;
    }
    

}
@media screen and (max-width: 989px){
  .new-bio-desk {
    
    flex-direction: column-reverse !important;
    
  }
  .new-bio-backround {
    background-color: #f8f8f8;

  }
  
  
  .testee-image img {
    width: 100%;
    height: auto;
  }
  .new-bio-social-mobile {
    display: none;
  }
  .testee h4 {
    font-size: 36px !important;
    font-weight: 300;
    margin-top: 2% !important;
    margin-bottom: 0%;
    
  }
  .bio-matsku {
    display: grid;
  }
  .bio-textia {
    width: 100% !important;
  }
  .bio-kuva {
    padding-top: 0px !important;
  }
  
 
  
}
@media screen and (max-width: 1192px ) {
  .bio-kuva {
    padding-top: 7% !important;
  }
}

.bio-kuva {
  padding-top: 2%;
}