
.testi {
    position: relative;
    opacity: transparent;
    top: 6px;
    
   
  
    
  }

  .overlay {
    height: 0%;
    width: 100%;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    background-color:white;
    
   
    background-color:white;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    opacity: 0.95;


    background-size: auto;


    overflow-y: hidden;
    transition: 0.7s;
    
  }
  
  .overlay-content {
    position: relative;
    top: 7%;
    width: 100%;
    text-align: center;
    margin-top: 30px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: normal;
  }
  a {
    
    text-decoration: none;
    
  }
  
   .overlay-content .works2 a {
    font-size:  22px;
    color:black;
   
   }
    
  
  .overlay a {
    padding: 10px;
    text-transform: uppercase;
    font-family: "Montserrat", sans-serif;
    text-decoration: none;
    color: black;
    display: block;
    transition: 0.4s;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: normal;

  }
  .overlay-content a {
    font-size: 35px;
    text-decoration: none;
    font-weight: normal;
    font-weight: 260;

  }
  .overlay p {
    text-align: center;
    color: #f1f1f1;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  }
  
  .hoo2 {
    text-align: right;
    font-size: 29px;
    padding: 4px;
    font-family: "Montserrat", sans-serif; 
  }
  .hoo2 a {
    color: black;
    
  }
  .navButton {
    margin-top: 7px;
    
  }
  
  
 
  .navlogos a{
    margin: inherit;
    display: block;
    text-align: center;
   
    left: -300px;
   
    display: inline;
    
  }
  
  
  .navbaar {
    position: fixed;
  
    position: -webkit-sticky; /* Safari */
    position: sticky;
    top: 0;
   
  }

  .overlay a:hover, .overlay a:focus {
    color: black;
    text-decoration: underline;
    

   
    
  }
  
  .overlay .closebtn {
    position: absolute;
    top: -10px;
    
    right: 7%;
    font-size: 60px;
    font-weight: 260;
  }
  
  @media screen and (max-height: 450px) {
    .overlay {overflow-y: auto;}
    .overlay a {font-size: 20px}
    .overlay .closebtn {
    font-size: 40px;
    top: 15px;
    right: 35px;
    }
  }
  @media screen and (max-width: 600px){
    .hoo2 img {
      height: 35px !important;
      width: 60% !important;
    }
    .hoo2 {
      margin-top: -3px;
      margin-bottom: 5px;
    }
    .navButton {
      margin-top: 0px !important;
      
    }
  }

  .hoo2> a {
    position: relative;
    color: #000;
    text-decoration: none;
  }
  
 .hoo2 > a:hover {
    color: #000;
  }
  .hoo2 > a:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: #000;
    visibility: hidden;
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transition: all 0.3s ease-in-out 0s;
    transition: all 0.3s ease-in-out 0s;
  }
  .hoo2 > a:hover:before {
    visibility: visible;
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }

  .hoo2 img {
    height: 50px;
    width: 300px
  }